import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import {HTMLContentParse} from "../components/Content";

export const ContactPageTemplate = ({
                                    feedback,
                                    title,
                                    subtitle,
                                    text,
                                    image,
                                    contact,
                                    titlePlace,
                                    subtitlePlace,
                                    places,
                                  }) => (
  <div className="Content">
    <div className="Article">
      <div className="featured-image" style={{
        backgroundImage: `url(${
          image && image.childImageSharp
            ? image.childImageSharp.fluid.src
            : image
          })`
      }}></div>
      <div className="entry-content">
        <h1 className="entry-title">{title}</h1>
        <div className="entry-lead">{subtitle}</div>
        <HTMLContentParse className="entry-body" content={text} />
        <HTMLContentParse className="entry-body" content={contact} />
      </div>
    </div>
    <div className="Article">
      <div className="entry-content">
        <h1 className="entry-title">{titlePlace}</h1>
        <div className="entry-lead">{subtitlePlace}</div>
        <HTMLContentParse className="entry-body" content={places} />
      </div>
    </div>
  </div>
)

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  places: PropTypes.string,
  office: PropTypes.string,
  contact: PropTypes.string,
  text: PropTypes.string,
  titlePlace: PropTypes.string,
  subtitlePlace: PropTypes.string,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <ContactPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        image={frontmatter.image}
        office={frontmatter.office}
        places={frontmatter.places}
        contact={frontmatter.contact}
        text={frontmatter.text}
        titlePlace={frontmatter.titlePlace}
        subtitlePlace={frontmatter.subtitlePlace}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        subtitle
        text
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        titlePlace
        subtitlePlace
        places
        contact
      }
    }
  }
`
